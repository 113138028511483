export interface AddressesData {
  address: string;
  region_fias_id?: string;
  area_fias_id?: string;
  city_fias_id?: string;
  street_fias_id?: string;
  house_fias_id?: string;
  settlement_fias_id?: string;
  sub_area_fias_id?: string;
  city_district_fias_id?: string;
  stead_fias_id?: string;
  room_fias_id?: string;
  flat_fias_id?: string;
}

export enum FiasLevel {
  Region = '1',
  Area = '3',
  SubArea = '-1',
  City = '4',
  CityDistrict = '5',
  Street = '7',
  House = '8',
  Settlement = '6',
  Stead = '75',
  Room = 9,
  Flat = 9,
}

export const addressFiasIdField = {
  [FiasLevel.Region]: 'region_fias_id',
  [FiasLevel.Area]: 'area_fias_id',
  [FiasLevel.SubArea]: 'sub_area_fias_id',
  [FiasLevel.City]: 'city_fias_id',
  [FiasLevel.CityDistrict]: 'city_district_fias_id',
  [FiasLevel.Settlement]: 'settlement_fias_id',
  [FiasLevel.Street]: 'street_fias_id',
  [FiasLevel.Stead]: 'stead_fias_id',
  [FiasLevel.House]: 'house_fias_id',
  [FiasLevel.Room]: 'room_fias_id',
  [FiasLevel.Flat]: 'flat_fias_id',
};
