/**
 * audio-player.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Import root app
import { App } from 'app';
import { StrictMode } from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
// Use consistent styling
import 'sanitize.css/sanitize.css';
import { configureAppStore } from 'store/store-configuration';
// Initialize languages
import './locales/i18n';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/react';
import reportWebVitals from './reportWebVitals';
import { Colors } from './app/themes/colors';
import { environment } from './app/environment';

Sentry.init({
  dsn: environment.sentryDSN,
  environment: environment.environment,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [environment.apiBaseUrl],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <StrictMode>
        <ThemeProvider theme={Colors}>
          <App />
        </ThemeProvider>
      </StrictMode>
    </HelmetProvider>
  </Provider>,
  MOUNT_NODE
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
