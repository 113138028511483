import React from 'react';
import { useDispatch } from 'react-redux';
import { AppealsActions } from 'store/appeals';
import { AppealInfoHeader } from '../AppealInfoHeader';
import { CommonInfo } from '../CommonInfo';
import { ChangeHistory } from '../ChangeHistory';
import { AppealInfoComponent } from './styled';
import { AppealFiles } from '../AppealFiles';
import { PaymentInfo } from '../PaymentInfo';
import { AppealId } from '../../../../models/appeal';
import { CallHistoryTab } from '../CallsHistoryTab/CallHistoryTab';

type AppealInfoProps = {
  appealId: AppealId;
};
export const AppealInfo = React.memo<AppealInfoProps>(props => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = React.useState<number>(0);

  const content = React.useMemo(() => {
    const contentList = [
      <CommonInfo />,
      <PaymentInfo />,
      <ChangeHistory />,
      <CallHistoryTab appealId={props.appealId} />,
      <AppealFiles appealId={props.appealId} />,
    ];

    return contentList[activeTab];
  }, [activeTab, props.appealId]);

  React.useEffect(() => {
    dispatch(AppealsActions.GetAppealById.init({ id: props.appealId }));
  }, [dispatch, props.appealId]);

  return (
    <AppealInfoComponent>
      <AppealInfoHeader appealId={props.appealId} activeTabIndex={activeTab} onActiveTabChange={setActiveTab} />

      {content}
    </AppealInfoComponent>
  );
});

AppealInfo.displayName = 'AppealInfo';
