import styled from 'styled-components';
import React, { FC } from 'react';
import { Colors } from 'app/themes/colors';
import { ErrorHint, TextRegular } from 'app/typography/text';
import { Option } from 'app/models/common/option';
import { ColumnFlexWithPadding, FlexWithSpacing } from 'app/typography/flex';
import { FormField } from '../FormField';
import { FormFieldError } from '../FormFieldError';

interface RadioProps {
  id: string;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  label?: string;
  name: string;
  className?: string;
  defaultChecked?: boolean;
}

export const Radio = ({ id, onChange, label, className, ...rest }: RadioProps) => (
  <RadioComponent className={className}>
    <input type="radio" {...rest} id={id} onChange={e => onChange(e.target.value)} />
    <label htmlFor={id}>
      <TextRegular>{label}</TextRegular>
    </label>
  </RadioComponent>
);

export interface RadioGroupProps {
  value: string;
  options: Option<string>[];
  onChange: (value: string) => void;
  name: string;
  label?: string;
  defaultChecked?: string;
  error?: string;
  disabled?: boolean;
}

export const RadioGroup = ({ label, options, onChange, name, defaultChecked, disabled }: Omit<RadioGroupProps, 'error'>) => (
  <FormField placeholder={label}>
    {options.map(option => (
      <Radio
        id={option.value}
        key={option.value}
        value={option.value}
        onChange={onChange}
        label={option.label}
        name={name}
        defaultChecked={option.value === defaultChecked}
        disabled={disabled}
      />
    ))}
  </FormField>
);

export const RadioGroupRow: FC<RadioGroupProps> = ({ label, options, onChange, name, defaultChecked, error, disabled }) => (
  <FormField placeholder={label}>
    <ColumnFlexWithPadding spacing="0.3125rem">
      <FlexWithSpacing spacing="10px">
        {options.map(option => (
          <RowRadio
            id={option.value}
            key={option.value}
            value={option.value}
            onChange={onChange}
            label={option.label}
            name={name}
            defaultChecked={option.value === defaultChecked}
            disabled={disabled}
          />
        ))}
      </FlexWithSpacing>
      <ErrorHint>{error && <FormFieldError error={error} />}</ErrorHint>
    </ColumnFlexWithPadding>
  </FormField>
);

const RowRadio = styled(Radio)`
  padding: 13px 15px;
  background: ${Colors.Blue400};
  border-radius: 6px;
`;

const RadioComponent = styled.div`
  width: 100%;

  > input {
    opacity: 0;
    display: none;
  }

  > input + label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 1px;
      width: 1.25rem;
      height: 1.25rem;
      border: 2px solid ${Colors.Blue600};
      border-radius: 50%;
    }

    &:after {
      content: '';
      position: absolute;
      top: 7px;
      left: 6px;
      height: 0.5rem;
      width: 0.5rem;
      border-radius: 50%;
      background: ${Colors.Blue600};
    }
  }

  > input:checked:focus + label,
  input:not(:checked):focus + label {
    &:before {
      border-color: ${Colors.Blue700};
    }
  }

  > input:checked + label {
    &:after {
      opacity: 1;
      background: ${Colors.Blue800};
    }

    &:before {
      border-color: ${Colors.Blue800};
    }
  }

  > input:not(:checked) + label {
    &:after {
      opacity: 0;
    }

    :hover {
      &:before {
        border-color: ${Colors.Blue700};
      }
    }
  }

  > input:disabled:not(:checked) + label {
    &:before {
      border-color: ${Colors.Grey700};
    }
  }

  > input:disabled + label {
    color: ${Colors.Grey700};

    &:before {
      border-color: ${Colors.Grey700};
    }
  }

  > input:disabled:checked + label {
    color: ${Colors.Grey700};

    &:after {
      background: ${Colors.Grey700};
    }

    &:before {
      border-color: ${Colors.Grey700};
    }
  }
`;
