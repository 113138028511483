import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { AppealsActions } from 'store/appeals';
import { webphoneActions } from 'store/webphone';
import { AppealRoute, RouteName, RouterParams } from 'app/constants/route-path';
import { useAction } from 'app/helpers/actions/use-action';
import { Page } from 'app/components/shared';
import { AnyAction } from '@reduxjs/toolkit';
import { useActionListener } from 'app/helpers/actions/action-listener.hook';
import { AppealInfo } from './components/AppealInfo';
import { AppealId } from '../../models/appeal';
import { withWebPhoneAppealPage } from './components/withWebPhoneAppealPage/withWebPhoneAppealPage';
import { LoadComments } from '../../../store/comments/comments.actions';

export const AppealPage = withWebPhoneAppealPage(() => {
  const dispatch = useDispatch();
  const { appealId: maybeAppealIdFromParams } = useParams<RouterParams>();
  const appealId = isNaN(Number(maybeAppealIdFromParams)) ? undefined : (Number(maybeAppealIdFromParams) as AppealId);

  const setOpenedAppealId = useAction(webphoneActions.setOpenedAppealId);

  useEffect(() => {
    appealId && setOpenedAppealId(appealId);

    return () => {
      setOpenedAppealId(undefined);
    };
  }, [appealId, setOpenedAppealId]);

  useActionListener((action: AnyAction) => {
    if (action.type === AppealsActions.ChangeAppealStatus.success.type && appealId) {
      dispatch(AppealsActions.GetAppealById.init({ id: appealId }));
    }
  });

  React.useEffect(() => {
    if (!appealId) return;
    dispatch(AppealsActions.GetAppealMessages.init({ id: appealId }));
    dispatch(LoadComments.init({ id: String(appealId), entity: 'appeal' }));
  }, [appealId, dispatch]);

  if (!appealId) return null;

  return (
    <Page title={RouteName[AppealRoute.Appeal]}>
      <AppealInfo appealId={appealId} />
    </Page>
  );
});
