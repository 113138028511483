import { HttpService } from '../http.service';
import { SpeechNotificationResponse, UpdateSpeechNotificationRequest } from './dtos';

class SpeechNotificationServiceImpl {
  private readonly apiEndpoint = '/api/calls/speech-notification/';

  loadSpeechNotification = async (): Promise<SpeechNotificationResponse> => {
    const { data } = await HttpService.get<SpeechNotificationResponse[]>(this.apiEndpoint);

    return data?.[0];
  };

  updateSpeechNotification = async (
    updateSpeechNotificationRequest: UpdateSpeechNotificationRequest
  ): Promise<SpeechNotificationResponse> => {
    const { data } = await HttpService.post<SpeechNotificationResponse>(this.apiEndpoint, updateSpeechNotificationRequest);

    return data;
  };
}

export const SpeechNotificationService = new SpeechNotificationServiceImpl();
