import { Accommodation } from 'app/models/accommodation';

import { CreateAccommodationDto, UpdateAccommodationDto } from './dtos';
import { HttpService } from '../http.service';

class AccommodationServiceImpl {
  private apiEndpoint = '/api/addresses';

  getAccommodation = async (id: string): Promise<Accommodation> => {
    const { data } = await HttpService.get<Accommodation>(`${this.apiEndpoint}/${id}/`);

    return data;
  };

  createAccommodation = async (createAccommodationDto: CreateAccommodationDto): Promise<Accommodation> => {
    const { data } = await HttpService.post<Accommodation>(this.apiEndpoint, createAccommodationDto);
    return data;
  };

  updateAccommodation = async ({ id, ...body }: UpdateAccommodationDto): Promise<Accommodation> => {
    const { data } = await HttpService.put<Accommodation>(`${this.apiEndpoint}/${id}`, body);

    return data;
  };

  deleteAccommodation = async (id: string): Promise<void> => {
    await HttpService.delete<void>(`${this.apiEndpoint}/${id}`);
  };
}

export const AccommodationService = new AccommodationServiceImpl();
