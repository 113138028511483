import { createSlice } from '@reduxjs/toolkit';

import { SpeechNotification } from 'app/models/speech-notification';
import { EntityState } from '../models/entity-state';
import { initialEntityState } from '../constants/initial-entity-state';
import { SPEECH_NOTIFICATION_STORE_NAME, SpeechNotificationActions } from './speech-notification.actions';

export interface SpeechNotificationState extends EntityState {
  speechNotification: SpeechNotification | null;
  error: any;
}

export const speechNotificationInitialState: SpeechNotificationState = {
  speechNotification: null,
  error: null,
  ...initialEntityState,
};

const speechNotificationSlice = createSlice({
  name: SPEECH_NOTIFICATION_STORE_NAME,
  initialState: speechNotificationInitialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(SpeechNotificationActions.LoadSpeechNotification.init, state => {
        state.loading = true;
      })
      .addCase(SpeechNotificationActions.LoadSpeechNotification.success, (state, { payload }) => {
        state.loading = false;
        state.speechNotification = payload;
      })
      .addCase(SpeechNotificationActions.LoadSpeechNotification.failure, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(SpeechNotificationActions.UpdateSpeechNotification.init, state => {
        state.updating = true;
      })
      .addCase(SpeechNotificationActions.UpdateSpeechNotification.success, (state, { payload }) => {
        state.updating = false;
        state.speechNotification = payload;
      })
      .addCase(SpeechNotificationActions.UpdateSpeechNotification.failure, (state, { payload }) => {
        state.updating = false;
        state.error = payload;
      }),
});

export const speechNotificationReducer = speechNotificationSlice.reducer;
