/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { CenteredFlex, CenteredFlexWithSpacing, ColumnFlexbox, Flexbox, VerticallyCenteredFlexWithSpaceBetween } from 'app/typography/flex';
import { ReactComponent as Logo } from 'app/themes/images/small-logo.svg';
import { Colors } from 'app/themes/colors';
import { IconClickable } from '../IconClickable';

export const RouteTabs = styled(CenteredFlexWithSpacing)``;

export const ToolbarComponent = styled(VerticallyCenteredFlexWithSpaceBetween)`
  background: ${Colors.MainBackground};
  padding: 1rem 2rem;
  position: sticky;
  top: 0;
  z-index: 9;
  box-shadow: 0 0 6px rgb(0 0 0 / 20%);
`;

export const Logotype = styled(Logo)`
  cursor: pointer;
`;

export const ProfileControls = styled(CenteredFlexWithSpacing)``;

export const ProfileIconComponent = styled(CenteredFlex)<{
  isRegistered: boolean;
  hasSipCredentials: boolean;
}>`
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
  background: ${Colors.Grey500};
  border-radius: 50%;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transition: 0.15s;
    background: ${({ isRegistered, hasSipCredentials }) =>
      isRegistered ? Colors.Green800 : hasSipCredentials ? Colors.Red800 : 'transparent'};
  }
`;

export const RobotIconComponent = styled(CenteredFlex)<{
  isActive: boolean | undefined;
}>`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    transition: 0.15s;
    background: ${({ isActive }) => (isActive ? Colors.Green800 : Colors.Red800)};
  }
`;

export const StyledIcon = styled(IconClickable)`
  fill: ${Colors.Grey700} !important;
  color: ${Colors.Grey700} !important;

  :hover {
    fill: ${Colors.Blue600} !important;
    color: ${Colors.Blue600} !important;
  }

  :active {
    fill: ${Colors.Blue700} !important;
    color: ${Colors.Blue700} !important;
  }
`;

export const TabTitle = styled.div<{
  active: boolean;
}>`
  padding: 0.25rem;
  color: ${({ active }) => (active ? Colors.Grey900 : Colors.Grey700)};
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    border-radius: 2px;
    left: 0;
    bottom: -1px;
    background: ${({ active }) => (active ? Colors.Blue800 : 'transparent')};
  }
`;

export const NotificationContainer = styled(Flexbox)`
  padding: 10px 15px;
  border-bottom: 1px solid ${Colors.Grey600};

  :first-child {
    padding: 15px 15px 10px 15px;
  }

  :last-child {
    padding: 10px 15px 15px 15px;
    border: none;
  }
`;
export const ToolbarNotificationsComponent = styled(Flexbox)`
  position: relative;
  z-index: 2;
`;

export const NotificationsContainer = styled(ColumnFlexbox)`
  width: 340px;
  right: 0;
  top: 25px;
  position: absolute;
  background: ${Colors.MainBackground};

  border: 1px solid ${Colors.Grey600};
  box-sizing: border-box;

  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
`;
