import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';

import { Option, OptionMenu } from 'app/models/common/option';
import { ConsumerAddress } from 'app/models/consumer';
import { ConsumersActions, ConsumersSelectors } from 'store/consumers';
import { webphoneActions } from 'store/webphone';
import { Icon, Tab, Tabs } from 'app/components/shared';
import { PagesRoutes } from 'app/constants/route-path';
import { useAction } from 'app/helpers/actions/use-action';
import { TextRegularBig } from 'app/typography/text';
import { formatPhoneNumber } from 'app/helpers/phone/phone';
import {
  AddressDropdown,
  CenteredFlexbox,
  ChevronLeft,
  ColumnFlexbox,
  ConsumerContextMenu,
  ConsumerInfoContainer,
  Dots,
  FullName,
  Header,
  Phone,
} from './styled';
import { AccommodationInfoTab } from '../AccommodationInfoTab/AccommodationInfoTab';
import { CallHistoryTab } from '../CallsHistoryTab/CallHistoryTab';
import { AppealsHistoryTab } from '../AppealsHistoryTab/AppealsHistoryTab';
import { useParams } from '../../../../helpers/hooks/use-params';

export enum ConsumerInfoTab {
  AccommodationInfo = 'accommodationInfo',
  AppealsHistory = 'appealsHistory',
  CallsHistory = 'callsHistory',
}

export const ConsumerInfo = () => {
  const selectedConsumer = useSelector(ConsumersSelectors.selectSelectedConsumer);

  const { consumerId } = useParams();
  const [query] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const call = useAction(webphoneActions.InitOutgoingRinging.init);
  const initOutgoingRinging = useAction(webphoneActions.InitOutgoingRinging.init);

  const [consumerAccommodations, setConsumerAccommodation] = useState<Option<ConsumerAddress>[]>([]);
  const [selectedConsumerAccommodation, setSelectedConsumerAccommodation] = useState<Option<ConsumerAddress>>();

  const menuOptions = [
    {
      label: 'Редактировать',
      callback: () =>
        navigate(
          generatePath(PagesRoutes.PAGES.CONSUMER_UPDATE, {
            consumerId: String(consumerId),
          })
        ),
    },
    {
      label: 'Позвонить',
      callback: () => {
        if (selectedConsumer?.phone) {
          initOutgoingRinging({ phone: selectedConsumer.phone });
        }
      },
    },
    consumerAccommodations.length > 0 && {
      label: 'Создать обращение',
      callback: () => navigate(PagesRoutes.PAGES.APPEAL_NEW),
    },
  ].filter(Boolean) as OptionMenu[];

  const goBack = (): void => {
    navigate(-1);
  };

  useEffect(() => {
    if (!consumerId) return;
    dispatch(ConsumersActions.LoadSelectedConsumer.init({ id: consumerId }));
  }, [dispatch, consumerId]);

  useEffect(() => {
    if (!selectedConsumer) return;

    const consumerAddresses = selectedConsumer?.addresses.map(address => ({
      label: address.address,
      value: address,
    }));
    setConsumerAccommodation(consumerAddresses);
    setSelectedConsumerAccommodation(consumerAddresses[0]);
  }, [selectedConsumer]);

  if (!selectedConsumer || !consumerId) return null;

  return (
    <ConsumerInfoContainer>
      <Header>
        <ColumnFlexbox>
          <CenteredFlexbox>
            <ChevronLeft icon="/assets/icons.svg#chevron-left" tooltip="Вернуться назад" onClick={goBack} />
            <FullName>{selectedConsumer.fio}</FullName>
            <Phone onClick={() => call({ phone: selectedConsumer.phone })} spacing="10px">
              <Icon icon="/assets/icons.svg#phone-in-talk" />
              <TextRegularBig>{formatPhoneNumber(selectedConsumer.phone)}</TextRegularBig>
            </Phone>
          </CenteredFlexbox>
          {consumerAccommodations.length ? (
            <AddressDropdown
              options={consumerAccommodations}
              value={selectedConsumerAccommodation}
              onChange={setSelectedConsumerAccommodation}
            />
          ) : null}
        </ColumnFlexbox>
        <ConsumerContextMenu options={menuOptions}>
          <Dots icon="/assets/icons.svg#menu" />
        </ConsumerContextMenu>
      </Header>
      <Tabs defaultActiveKey={query.get('activeTab') || ConsumerInfoTab.AccommodationInfo}>
        <Tab tabKey={ConsumerInfoTab.AccommodationInfo} title="Информация о помещении">
          <AccommodationInfoTab accommodation={selectedConsumerAccommodation?.value} />
        </Tab>
        <Tab tabKey={ConsumerInfoTab.AppealsHistory} title="История обращений">
          <AppealsHistoryTab addressId={selectedConsumerAccommodation?.value?.id} addressLabel={selectedConsumerAccommodation?.label} />
        </Tab>
        <Tab tabKey={ConsumerInfoTab.CallsHistory} title="История звонков">
          <CallHistoryTab consumerId={consumerId} />
        </Tab>
      </Tabs>
    </ConsumerInfoContainer>
  );
};
