import React, { FC, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { Call, CallDirection } from 'app/models/call';
import { AudioPlayer, AudioPlayerType, CallDuration, Dialog, FormField, StyledLink, Textarea } from 'app/components/shared';
import { ColumnFlexWithPadding } from 'app/typography/flex';
import { HeaderSmall, TextRegularBig } from 'app/typography/text';
import { useAction } from 'app/helpers/actions/use-action';

import * as CallActions from 'store/calls/calls.actions';
import { webphoneActions } from 'store/webphone';

import { ModalProps } from 'app/services/modal-service/modal.service';
import { CallsService } from 'app/services/calls-service';
import { PagesRoutes } from 'app/constants/route-path';
import { formatPhoneNumber } from 'app/helpers/phone';

import { generatePath, useNavigate } from 'react-router-dom';
import { AppealNumberText, CallTypeIcon, Consumer, FormRow, SaveButton } from './styled';

interface CallDialogProps extends ModalProps {
  call: Call;
}

export const CallDialog = ({ call, onClose = () => {} }: CallDialogProps) => {
  const appealId = call.appeal?.id;
  const appealNumber = call.appeal?.number;

  return (
    <Dialog
      headerContent={
        <HeaderSmall>
          {appealId ? (
            <>
              Звонок по
              <AppealNumberText>
                <StyledLink to={`appeals/${appealId}`} onClick={onClose}>
                  {' обращению №'}
                  {appealNumber}
                </StyledLink>
              </AppealNumberText>
            </>
          ) : (
            <>Звонок —</>
          )}
        </HeaderSmall>
      }
      body={<DialogBody call={call} onClose={onClose} />}
      onClose={onClose}
    />
  );
};

interface DialogBodyProps {
  call: Call;
  onClose(): void;
}

const DialogBody: FC<DialogBodyProps> = ({ call, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [comment, setComment] = useState('');
  const [recordUrl, setRecordUrl] = useState('');

  const [recordError, setRecordError] = useState('');

  const makeCall = useAction(webphoneActions.InitOutgoingRinging.init);
  const { callerPhone, consumer, durationInSec } = call;

  const onSaveButtonClick = React.useCallback(() => {
    const { id } = call;

    dispatch(
      CallActions.UpdateCall.init({
        id,
        updateCallDto: {
          comment,
        },
      })
    );

    onClose();
  }, [call, comment, dispatch, onClose]);

  const handleConsumerClick = React.useCallback(() => {
    if (consumer?.id) {
      navigate(
        generatePath(PagesRoutes.PAGES.CONSUMER, {
          consumerId: String(consumer.id),
        })
      );

      onClose();
      return;
    }

    const phone: string = (call.consumer?.phone || call.callerPhone)!;

    makeCall({ phone, options: { prefix: call?.managecompany?.prefix } });

    onClose();
  }, [call.callerPhone, call.consumer?.phone, call?.managecompany?.prefix, consumer?.id, makeCall, navigate, onClose]);

  const callType = call.type || CallDirection.Outgoing;

  useEffect(() => {
    const loadAudio = async () => {
      try {
        const record = await CallsService.getCallRecord(call.id);

        setRecordUrl(record);
      } catch (e) {
        setRecordError(e);
      }
    };

    loadAudio().then();
  }, [call]);

  useEffect(() => {
    if (call.comment) {
      setComment(call.comment);
    }
  }, [call.comment]);

  return (
    <ColumnFlexWithPadding spacing="1.875rem">
      <AudioPlayer src={recordUrl} error={!!recordError} type={AudioPlayerType.Extended} />
      <ColumnFlexWithPadding spacing="1.25rem">
        <FormRow spacing="1.375rem">
          <FormField placeholder="Абонент" spacing="0.3125rem">
            {consumer?.fio || callerPhone ? (
              <Consumer onClick={handleConsumerClick}>{consumer?.fio || formatPhoneNumber(callerPhone)}</Consumer>
            ) : (
              <TextRegularBig>Не определен</TextRegularBig>
            )}
          </FormField>
          <CallTypeIcon icon={`/assets/icons.svg#${callType}`} type={callType} />
          <FormField placeholder="Оператор" spacing="0.3125rem">
            {call.dispatcher?.fullName ? (
              <TextRegularBig>{call.dispatcher?.fullName}</TextRegularBig>
            ) : (
              <TextRegularBig>Пропущенный</TextRegularBig>
            )}
          </FormField>
        </FormRow>

        <FormRow spacing="3.75rem">
          <FormField placeholder="Дата и время" spacing="0.3125rem">
            <TextRegularBig>{format(new Date(call.createdAt), 'dd.MM.yyyy HH:mm')}</TextRegularBig>
          </FormField>

          <FormField placeholder="Продолжительность" spacing="0.3125rem">
            <CallDuration durationInSec={durationInSec} />
          </FormField>
        </FormRow>
      </ColumnFlexWithPadding>

      <FormField placeholder="Комментарий" spacing="0.3125rem">
        <Textarea value={comment} onChange={e => setComment(e.target.value)} placeholder="Введите комментарий" />
      </FormField>

      <SaveButton mod="primary" onClick={onSaveButtonClick}>
        Сохранить
      </SaveButton>
    </ColumnFlexWithPadding>
  );
};
