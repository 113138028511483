import { Colors } from 'app/themes/colors';

export const autocompleteStyles = `
   .react-dadata__container {
     position: relative;
     width: 100%;
     
     &--error .react-dadata__input {
       box-shadow: 0 0 0 2px ${Colors.Red800};
       background: ${Colors.MainBackground};
     }
   }
   
  .react-dadata__input {
    width: 100%;
    background: ${Colors.Blue400};
    padding: 15px 20px;
    line-height: 16px;
    font-size: 14px;
    border-radius: 6px;
    border: none;
    
    &::placeholder {
      color: ${Colors.Grey700};
    }
  
    &:hover {
      box-shadow: 0 0 0 1px ${Colors.Blue600};
    }

    &:focus {
      box-shadow: 0 0 0 2px ${Colors.Blue700};
      background: ${Colors.MainBackground};
      outline: none;
    }
    
    &:read-only {
      box-shadow: none;
      background: ${Colors.Grey500};
      color: ${Colors.Grey700};
      cursor: not-allowed;
    }
  }
  
  .react-dadata__suggestions {
    border: 1px solid ${Colors.Grey600};
    background: ${Colors.MainBackground};
    padding: 30px;
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    position: absolute;
    top: calc(100% + 5px);
    width: 100%;
    z-index: 10;
    overflow: hidden;
  }
  
  .react-dadata__suggestion {
    cursor: pointer;
    width: 100%;
    background: none;
    border: none;
    padding: 6px 20px;
    text-align: left;
    font-size: 14px;
    
    &:last-of-type {
      padding-bottom: 15px;
    }
    
    &:first-of-type {
      padding-top: 15px;
    }
  }
  
  .react-dadata--highlighted {
    color: ${Colors.Blue700};
  }
`;
