/* eslint-disable */

import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { MultiSelectProps, Option } from './models';
import { Colors } from '../../../themes/colors';
import { SelectOption } from './SelectOption';
import { useOnClickOutside } from '../../../helpers/click-outside/click-outside.hook';
import { Icon, IconClickable, Scrollbar } from 'app/components/shared';

import { Preloader } from '../Preloader';

const DEFAULT_NO_OPTIONS_LABEL = 'Записи не найдены';

export const MultiSelect: FC<MultiSelectProps> = ({
  options,
  value,
  defaultIsOpen,
  placeholder,
  labelledBy,
  className,
  disabled,
  onChange,
  closeOnChangedValue,
  noOptionsLabel,
  isLoading,
}) => {
  const [expanded, setExpanded] = useState(defaultIsOpen);
  const [search, setSearch] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);

  const [focusIndex, setFocusIndex] = useState(0);
  const containerRef = useRef<any>();
  const optionsRef = useRef<any>();

  const handleItemClicked = (index: number) => setFocusIndex(index);

  const handleSelectionChanged = (option: Option, checked: boolean) => {
    if (disabled) return;

    onChange(checked ? [...value, option] : value.filter((o: any) => o.value !== option.value));
  };

  const removeValue = option => {
    onChange(value.filter((o: any) => o.value !== option.value));
  };

  useOnClickOutside(containerRef, () => setExpanded(false), true);

  useEffect(() => {
    containerRef?.current?.querySelector(`[tabIndex='${focusIndex}']`)?.focus();
  }, [focusIndex]);

  useEffect(() => {
    if (closeOnChangedValue) {
      setExpanded(false);
    }
  }, [value]);

  useEffect(() => {
    if (search) {
      setFilteredOptions(options.filter(o => o.label.toLowerCase().includes(search.trim().toLowerCase())));
    } else {
      setFilteredOptions(options);
    }
  }, [options]);

  useEffect(() => {
    if (search) {
      setFilteredOptions(options.filter(o => o.label.toLowerCase().includes(search.trim().toLowerCase())));
    } else {
      setFilteredOptions(options);
    }
  }, [search]);

  return (
    <SelectContainer className={className} ref={containerRef}>
      <SelectHeader
        value={search}
        aria-labelledby={labelledBy}
        aria-expanded={expanded}
        aria-disabled={disabled}
        placeholder={placeholder}
        onFocus={() => setExpanded(true)}
        onChange={event => setSearch(event.target.value)}
      />
      {expanded && (
        <SelectPanelContainer ref={optionsRef}>
          <SelectPanel>
            <Scrollbar autoHeight autoHeightMax={160}>
              <Options>
                {filteredOptions.length ? (
                  filteredOptions.map((option, index) => (
                    <OptionItem key={option?.key || index}>
                      <SelectOption
                        option={option}
                        tabIndex={index}
                        checked={!!value.find(s => s.value === option.value)}
                        onSelectionChanged={checked => handleSelectionChanged(option, checked)}
                        onClick={(_, index) => handleItemClicked(index)}
                      />
                    </OptionItem>
                  ))
                ) : isLoading ? (
                  <StyledPreloader />
                ) : (
                  <NoOptionItem>{noOptionsLabel || DEFAULT_NO_OPTIONS_LABEL}</NoOptionItem>
                )}
              </Options>
            </Scrollbar>
          </SelectPanel>
        </SelectPanelContainer>
      )}
      {value && (
        <ChipsWrapper>
          <Scrollbar autoHeight autoHeightMax={60}>
            <ChipsContainer>
              {value.map(v => (
                <Chip title={v.label}>
                  <ChipLabel>{v.label}</ChipLabel>
                  <CloseIcon icon="/assets/icons.svg#close" onClick={() => removeValue(v)} />
                </Chip>
              ))}
            </ChipsContainer>
          </Scrollbar>
        </ChipsWrapper>
      )}
    </SelectContainer>
  );
};

const SelectContainer = styled.div`
  position: relative;
  transition: all 0.2s ease;
`;

const SelectHeader = styled.input`
  border-radius: 6px;
  width: 100%;
  background: ${Colors.Blue400};
  color: ${Colors.Grey900};
  font-size: 14px;
  line-height: 16px;
  padding: 13px 16px;
  border: 2px solid transparent;

  &::placeholder {
    font-weight: normal;
    color: ${Colors.Grey700};
  }

  &:hover {
    box-shadow: 0 0 0 1px ${Colors.Blue600};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${Colors.Blue700};
    background: ${Colors.MainBackground};
    outline: none;
  }
`;

const SelectPanelContainer = styled.div`
  z-index: 10;
  position: absolute;
  padding-top: 5px;
  width: 100%;
`;

const SelectPanel = styled.div`
  background: ${Colors.White};
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
`;

const Options = styled.ul`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 5px;
  padding: 12px !important;
`;

const OptionItem = styled.li``;

const ChipsWrapper = styled.div`
  padding-top: 6px;
`;

const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const Chip = styled.div`
  background: ${Colors.Blue600};
  color: ${Colors.White};
  border-radius: 4px;
  padding: 5px 8px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
`;

const ChipLabel = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 445px;
`;

export const CloseIcon = styled(Icon)`
  color: ${Colors.White};
  width: 16px;
  height: 16px;
  cursor: pointer;

  &:hover {
    color: ${Colors.Blue700};
  }
` as typeof IconClickable;

export const NoOptionItem = styled.li`
  font-size: 14px;
`;

export const StyledPreloader = styled(Preloader)`
  width: 30px;
  height: 30px;
  margin: 0 auto;
`;
