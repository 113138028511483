import { useOnClickOutside } from 'app/helpers/click-outside/click-outside.hook';
import { OptionMenu } from 'app/models/common/option';
import React, { createRef, ReactNode, RefObject, useState } from 'react';
import styled from 'styled-components';
import { Colors } from '../../../themes/colors';
import { ColumnFlexWithPadding } from '../../../typography/flex';
import { Scrollbar } from '../Scrollbar';

interface MenuProps {
  options: OptionMenu[];
  className?: string;
  children: ReactNode;
  fullWidth?: boolean;
  disabled?: boolean;
}

const MENU_MAX_HEIGHT = 200;

export const Menu = (props: MenuProps) => {
  const { className, children, fullWidth, options, disabled } = props;
  const [isOpen, setIsOpen] = useState(false);
  const componentRef: RefObject<HTMLDivElement> = createRef();
  const hideMenu = () => {
    setIsOpen(false);
  };

  const clickOutsideEventHandler = ($event: MouseEvent | TouchEvent) => {
    $event.stopPropagation();
    if (isOpen && !componentRef.current!.contains($event.target as HTMLElement)) {
      hideMenu();
    }
  };

  const onOptionClick = (e, option: OptionMenu) => {
    e.stopPropagation();
    option.callback(e);
    hideMenu();
  };

  const onClickAnchor = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!disabled) {
      e.stopPropagation();
      setIsOpen(!isOpen);
    }
  };

  useOnClickOutside(componentRef, $event => clickOutsideEventHandler($event), isOpen);

  return (
    <MenuContainer className={className} ref={componentRef}>
      <div onClick={e => onClickAnchor(e)}>{children}</div>
      <MenuComponent visible={isOpen} fullWidth={fullWidth}>
        <Scrollbar autoHeight autoHeightMax={MENU_MAX_HEIGHT}>
          <MenuOptionsContainer spacing="0.75rem">
            {options.map(option => (
              <MenuOption key={option.label} onClick={e => onOptionClick(e, option)}>
                {option.label}
              </MenuOption>
            ))}
          </MenuOptionsContainer>
        </Scrollbar>
      </MenuComponent>
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  position: relative;

  input:read-only:not(:disabled) {
    cursor: pointer;
  }
`;

const MenuComponent = styled.div<{ visible: boolean; fullWidth?: boolean }>`
  ${props => (props.fullWidth ? 'width:100%' : '')};
  ${props => !props.visible && 'display:none'};
  background: ${Colors.MainBackground};
  border: 1px solid ${Colors.Grey600};
  box-sizing: border-box;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.08);
  border-radius: 0.375rem;
  margin-top: 5px;
  position: absolute;
  right: 0;
  z-index: 2;
`;

const MenuOptionsContainer = styled(ColumnFlexWithPadding)`
  padding: 1rem 1.25rem;
`;

const MenuOption = styled.option`
  color: ${Colors.Grey900};
  background: ${Colors.MainBackground};
  display: flex;
  white-space: pre;
  cursor: pointer;
  font-size: 14px;

  :hover {
    color: ${Colors.Blue700};
  }
`;
