import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Column } from 'react-table';

import * as AppealSelectors from 'store/appeals/appeals.selectors';
import { AppealChangesHistoryEntry } from 'app/models/appeal/appeal-changes-history-entry';
import { localFormat } from 'app/helpers/date/time';
import { AppealInfoTable, TabComponent } from '../../styled';
import { TableScrollbar } from '../../../../components/shared';

export const ChangeHistory = () => {
  const appeal = useSelector(AppealSelectors.selectSelectedAppeal, shallowEqual);
  const isLoading = useSelector(AppealSelectors.selectLoading, shallowEqual);

  const columns: Column[] = [
    {
      Header: 'Дата',
      id: 'date',
      accessor: 'createdAt',
      Cell: cellProps => {
        const historyChange = cellProps.row.original as AppealChangesHistoryEntry;
        return localFormat(new Date(historyChange.createdAt), 'dd.MM.yyyy');
      },
    },
    {
      Header: 'Время',
      id: 'time',
      accessor: 'createdAt',
      Cell: cellProps => {
        const historyChange = cellProps.row.original as AppealChangesHistoryEntry;
        return localFormat(new Date(historyChange.createdAt), 'HH:mm');
      },
    },
    {
      Header: 'Сообщение',
      id: 'message',
      accessor: 'message',
      Cell: cellProps => {
        const historyChange = cellProps.row.original as AppealChangesHistoryEntry;
        // eslint-disable-next-line react/no-danger
        return <div dangerouslySetInnerHTML={{ __html: historyChange.message }} />;
      },
    },
    {
      Header: 'Оператор',
      id: 'dispatcher.fullName',
      accessor: 'dispatcher.fullName',
    },
  ];

  const getTableData = React.useCallback(() => {
    if (appeal?.changesHistoryEntries) return appeal.changesHistoryEntries;
    return [];
  }, [appeal?.changesHistoryEntries]);

  return (
    <TabComponent>
      <TableScrollbar>
        <AppealInfoTable columns={columns} emptyTableTitle="История пуста" data={getTableData()} isLoading={isLoading} />
      </TableScrollbar>
    </TabComponent>
  );
};
