import { TableFilter } from 'app/models/configuration/table-structure';
import { Call, CallsQueryParams, SipCallId } from 'app/models/call';
import { CreateCallRequest } from 'app/services/calls-service';
import { ChangeChosenFilterPayload } from '../models/change-chosen-filter-payload';
import { getCreateActionTrio, getCreatePayloadActionFromStore } from '../helpers/action-create';
import { UpdateCallPayload } from './models/update-call-payload';

export const CALLS_STORE_NAME = 'calls';

const createPayloadAction = getCreatePayloadActionFromStore(CALLS_STORE_NAME);
const createActionTrio = getCreateActionTrio(CALLS_STORE_NAME);

export const LoadCalls = createActionTrio<CallsQueryParams, { callsTotalCount: number; page: number; calls: Call[] }>('load calls');

export const LoadCallsCreatedGte = createActionTrio<CallsQueryParams, { callsTotalCount: number; page: number; calls: Call[] }>(
  'load calls created gte'
);

export const CreateCall = createActionTrio<CreateCallRequest, Call>('create call');

export const UpdateCall = createActionTrio<UpdateCallPayload, Call>('update call');

export const GetIncomingCallId = createPayloadAction<SipCallId>('save incoming callId');

export const setChosenFilter = createPayloadAction<{ filters: TableFilter[] }>('set chosen filter');
export const changeChosenFilter = createPayloadAction<ChangeChosenFilterPayload>('change chosen filter');
export const resetSomeFilters = createPayloadAction<string[]>('reset some filters');
