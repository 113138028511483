import { ColumnFlexbox, ColumnFlexWithPadding } from 'app/typography/flex';
import { ErrorHint } from 'app/typography/text';
import React, { KeyboardEvent, useState } from 'react';
import styled from 'styled-components';
import { Colors } from 'app/themes/colors';
import { Key } from 'app/constants/key';
import { FormFieldError } from '../FormFieldError';
import { Icon } from '../IconBackground';
import { FormField } from '../FormField';

export enum InputHeight {
  Big = '1rem',
  Small = '0.75rem',
}

export interface InputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  error?: string;
  isValid?: boolean;
  search?: boolean;
  noError?: boolean;
  fontSize?: string;
  icon?: string;
  placeholder?: string;
}

export const Input = ({
  value,
  isValid,
  search,
  children,
  className,
  type,
  height,
  noError,
  error,
  fontSize,
  icon,
  onKeyDown,
  name,
  ...rest
}: InputProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    onKeyDown?.(e);
    if (type === 'number' && (e.code === Key.ArrowUp || e.code === Key.ArrowDown)) {
      e.preventDefault();
    }
  };

  const inputProps = {
    onKeyDown: handleKeyDown,
    ...(rest as any),
    type: type === 'password' && isPasswordVisible ? 'text' : type,
    value,
    onChange: e => {
      rest.onChange?.(e);
    },
  };

  const isInputInvalid = ((isValid !== undefined && !isValid) || !!error) && !inputProps.disabled;

  return (
    <InputComponent spacing="0.3125rem">
      <InputContainer className={className}>
        {search && (
          <SearchIconContainer>
            <StyledIcon icon="/assets/icons.svg#search" disabled={inputProps.disabled} />
          </SearchIconContainer>
        )}
        <StyledInput
          {...inputProps}
          name={name}
          className={isInputInvalid ? 'error' : ''}
          isSearch={search}
          password={type === 'password'}
          height={height || InputHeight.Big}
          fontSize={fontSize}
        >
          {children}
        </StyledInput>
        {icon && (
          <IconContainer>
            <StyledIcon icon={`/assets/icons.svg#${icon}`} disabled={inputProps.disabled} />
          </IconContainer>
        )}
        {type === 'password' && (
          <IconContainer onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
            <StyledIcon
              icon={isPasswordVisible ? '/assets/icons.svg#eye-close' : '/assets/icons.svg#eye-open'}
              disabled={inputProps.disabled}
            />
          </IconContainer>
        )}
      </InputContainer>
      {!noError && <ErrorHint>{isInputInvalid && !!error && <FormFieldError error={error} />}</ErrorHint>}
    </InputComponent>
  );
};

export interface FormInputProps extends InputProps {
  label?: string;
}

export const FormInput = (props: FormInputProps) => {
  const { label, ...rest } = props;

  return (
    <StyledField placeholder={label}>
      <Input {...rest} />
    </StyledField>
  );
};

const StyledField = styled(FormField)`
  width: 100%;
`;

const InputContainer = styled(ColumnFlexbox)`
  position: relative;
`;

const StyledInputIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const IconContainer = styled(StyledInputIcon)`
  right: 0.625rem;
`;

const SearchIconContainer = styled(StyledInputIcon)`
  left: 0.625rem;
`;

const StyledIcon = styled(Icon)<{ disabled: boolean }>`
  color: ${Colors.Grey700};

  :hover {
    color: ${Colors.Blue600};
  }

  :active {
    color: ${Colors.Blue700};
  }

  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
      
      :hover, :active {
        color: ${Colors.Grey700};
      }
  `}
`;

const InputComponent = styled(ColumnFlexWithPadding)``;

const StyledInput = styled.input<{
  isSearch?: boolean;
  password?: boolean;
  height: InputHeight;
  fontSize?: string;
}>`
  appearance: none;

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }

  &::-webkit-credentials-auto-fill-button {
    margin: 0;
    width: 0;
    background-color: transparent;
  }

  background: ${Colors.Blue400};
  border: none;
  border-radius: 0.378rem;
  color: ${Colors.Grey900};
  font-size: ${props => (props.fontSize ? props.fontSize : '0.875rem')};
  line-height: 1rem;
  padding: ${props => `${props.height} ${props.password ? '2.8125rem' : '1rem'} ${props.height} ${props.isSearch ? '2.8125rem' : '1rem'}`};

  &::placeholder {
    font-weight: normal;
    color: ${Colors.Grey700};
  }

  &:hover {
    box-shadow: 0 0 0 1px ${Colors.Blue600};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${Colors.Blue700};
    background: ${Colors.MainBackground};
    outline: none;
  }

  &:disabled {
    background: ${Colors.Grey500};
    color: ${Colors.Grey700};
    box-shadow: none;
    cursor: not-allowed;
  }

  &:read-only:not(:disabled) {
    box-shadow: none;
    cursor: auto;
    background: ${Colors.Blue400};
  }

  &.error {
    box-shadow: 0 0 0 2px ${Colors.Red800};
    background: ${Colors.MainBackground};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;
