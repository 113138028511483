import React, { FC, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { array, boolean, object, string } from 'yup';

import { AudioPlayer, AudioPlayerType, FormField, MultiSelect, Option } from 'app/components/shared';
import { HeaderSmall } from 'app/typography/text';
import { ColumnFlexWithPadding } from 'app/typography/flex';
import { NotificationsActions } from 'store/notifications';
import { SuppliersSelectors } from 'store/suppliers';
import { SpeechNotificationActions } from 'store/speech-notification';
import { useLockBodyScroll } from 'app/helpers';
import { SpeechService } from '../../services/speech/speech.service';
import { useAction } from '../../helpers/actions/use-action';
import { NotificationType } from '../../models/notifications/notification';
import { SaveButton, StyledCheckbox, StyledDialog, StyledTextArea } from './styled';
import { SpeechNotification } from '../../models/speech-notification';
import { selectSpeechNotification } from '../../../store/speech-notification/speech-notification.selectors';

interface Props {
  onClose(): void;
}

interface SpeechNotificationForm {
  active: boolean;
  suppliers: Option[];
  text: string;
}

const AutomaticVoiceNotificationsBody: FC<Props> = ({ onClose }) => {
  const [record, setRecord] = useState('');
  const [savedText, setSavedText] = useState('');

  const suppliersOptions = useSelector(SuppliersSelectors.selectSuppliersOptions) || [];
  const speechNotification: SpeechNotification | null = useSelector(selectSpeechNotification, shallowEqual);

  const dispatch = useDispatch();

  const isUpdating = !!speechNotification?.id;

  const { values, handleChange, submitForm, isValid, dirty, setFieldValue, setValues } = useFormik<SpeechNotificationForm>({
    initialValues: {
      text: '',
      suppliers: [],
      active: false,
    },
    validationSchema: object().shape({
      text: string().required(),
      suppliers: array().min(1).required(),
      active: boolean(),
    }),
    onSubmit: values => updateSpeechNotification(values),
  });

  const addNotification = useAction(NotificationsActions.AddNotification.init);

  const synthesizeTextToSpeech = useCallback(
    async (text: string, beforeLoad?: () => void, afterLoad?: () => void) => {
      try {
        if (!text || savedText === text.trim()) {
          return;
        }
        beforeLoad?.();
        const record = await SpeechService.synthesizeTextToSpeech({ text: text.trim() });
        setRecord(record);
        setSavedText(text.trim());
      } catch (error) {
        addNotification({
          body: 'Во время получения записи произошла ошибка.',
          type: NotificationType.Error,
        });
      } finally {
        afterLoad?.();
      }
    },
    [savedText]
  );

  const handleNotificationChange = event => {
    handleChange('text')(event.target.value);
  };

  const updateSpeechNotification = (values: SpeechNotificationForm) => {
    const suppliers = values.suppliers.map(({ value }) => value);
    dispatch(SpeechNotificationActions.UpdateSpeechNotification.init({ text: values.text, suppliers, active: values.active }));
    onClose();
  };

  const buildSupplierOptions = (ids: string[]): Option[] => {
    const options: Option[] = [];

    for (let i = 0; i < ids.length; i++) {
      for (let j = 0; j < suppliersOptions.length; j++) {
        if (suppliersOptions[j].value === ids[i]) {
          options.push(suppliersOptions[j]);
        }
      }
    }

    return options;
  };

  useEffect(() => {
    if (!speechNotification) {
      return;
    }

    const suppliers = buildSupplierOptions(speechNotification.suppliers);

    setValues({ text: speechNotification.text, active: speechNotification.active, suppliers });

    synthesizeTextToSpeech(speechNotification.text).then();
  }, [speechNotification]);

  return (
    <div>
      <ColumnFlexWithPadding spacing="30px">
        <ColumnFlexWithPadding spacing="10px">
          <FormField placeholder="Текст уведомления" spacing="0.25rem">
            <StyledTextArea
              value={values.text}
              id="automatic-voice-notification-text"
              placeholder="Введите текст уведомления"
              onChange={handleNotificationChange}
            />
          </FormField>
          <AudioPlayer
            src={record}
            type={AudioPlayerType.Extended}
            onPlayClick={async (beforeLoad, afterLoad) => synthesizeTextToSpeech(values.text, beforeLoad, afterLoad)}
          />
        </ColumnFlexWithPadding>
        <FormField placeholder="Управляющие компании" spacing="0.25rem">
          <MultiSelect
            onChange={value => setFieldValue('suppliers', value)}
            labelledBy="management-companies"
            options={suppliersOptions}
            value={values.suppliers}
            placeholder="Введите название компании"
          />
        </FormField>
        <FormField placeholder="Настройки" spacing="0.25rem">
          <StyledCheckbox checked={values.active} id="settings" onChange={handleChange('active')} label="Включить голосовое уведомление" />
        </FormField>
        <SaveButton mod="primary" disabled={!isValid || (!dirty && !isUpdating)} onClick={submitForm}>
          Сохранить
        </SaveButton>
      </ColumnFlexWithPadding>
    </div>
  );
};

export const AutomaticVoiceNotificationsDialog: FC<Props> = ({ onClose }) => {
  useLockBodyScroll();

  return (
    <StyledDialog
      headerContent={<HeaderSmall>Автоматическое голосовое уведомление</HeaderSmall>}
      body={<AutomaticVoiceNotificationsBody onClose={onClose} />}
      onClose={onClose}
    />
  );
};
