import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../types/AppState.type';
import { CallStatus, WebphoneType } from './models';
import { WebPhoneState } from './webphone.slice';
import { APPEAL_STATUS } from '../../app/constants/appeals';

const selectWebPhoneState: (state: AppState) => WebPhoneState = state => state.webphone;

const selectCallStatus = createSelector(selectWebPhoneState, state => state?.callStatus);

const selectParticipant = createSelector(selectWebPhoneState, state => state?.participant);

const selectParticipantActiveAppeals = createSelector(selectParticipant, state => {
  return state?.appeals.filter(
    appeal =>
      appeal.status !== APPEAL_STATUS.completed && appeal.status !== APPEAL_STATUS.closed && appeal.status !== APPEAL_STATUS.canceled
  );
});

const selectParticipantPhone = createSelector(selectWebPhoneState, state => state?.participantPhone);

const selectCallStartDate = createSelector(selectWebPhoneState, state => state?.callStartDate);

const selectMembersQueueCount = createSelector(selectWebPhoneState, state => state?.membersQueueCount);

const selectIsShowWebPhone = createSelector(
  selectCallStatus,
  status =>
    status === CallStatus.IncomingRinging ||
    status === CallStatus.InTalk ||
    status === CallStatus.OutgoingRinging ||
    status === CallStatus.EstablishingIncomingCall
);
const selectWebphoneType = createSelector(selectWebPhoneState, ({ webphoneType }) => webphoneType);

const selectDispatcherComment = createSelector(selectWebPhoneState, ({ dispatcherComment }) => dispatcherComment);

const selectIsModalWebPhoneShown = createSelector(
  selectIsShowWebPhone,
  selectWebphoneType,
  (isShown, type) => isShown && type === WebphoneType.Modal
);

const selectIsComponentWebPhoneShown = createSelector(
  selectIsShowWebPhone,
  selectWebphoneType,
  (isShown, type) => isShown && type === WebphoneType.Component
);

const selectIsCallDurationLimitExceeded = createSelector(
  selectWebPhoneState,
  ({ isCallDurationLimitExceeded }) => isCallDurationLimitExceeded
);

const selectIsRegistered = createSelector(selectWebPhoneState, ({ isRegistered }) => isRegistered);

const selectParticipantSupplier = createSelector(selectWebPhoneState, ({ supplier }) => supplier);

const selectOpenedAppealId = createSelector(selectWebPhoneState, ({ openedAppealId }) => openedAppealId);

export const webPhoneSelectors = {
  selectCallStatus,
  selectIsShowWebPhone,
  selectParticipant,
  selectParticipantPhone,
  selectCallStartDate,
  selectMembersQueueCount,
  selectWebphoneType,
  selectDispatcherComment,
  selectIsModalWebPhoneShown,
  selectIsComponentWebPhoneShown,
  selectIsCallDurationLimitExceeded,
  selectIsRegistered,
  selectParticipantSupplier,
  selectOpenedAppealId,
  selectParticipantActiveAppeals,
};
