import Axios from 'axios';

import { environment } from '../../environment';
import { TTSOptions } from './models/TTSOptions';

class SpeechServiceImpl {
  private apiEndpoint = environment.yandexSpeechKitURL || 'https://functions.yandexcloud.net/d4e3f3qttu75b8hce4el';
  private defaultTTSOptions: TTSOptions = {
    text: '',
    lang: 'ru-RU',
    voice: 'alena',
  };

  synthesizeTextToSpeech = async (options: TTSOptions): Promise<string> => {
    const body = { ...this.defaultTTSOptions, ...options };

    const params = {
      text: body.text,
    };

    const headers = {
      Authorization: `Api-Key ${environment.yandexAPIKey}`,
    };

    const { data } = await Axios.get(this.apiEndpoint, {
      headers,
      params,
      responseType: 'arraybuffer',
    });

    const blob = new Blob([data], {
      type: 'audio/mpeg',
    });

    return URL.createObjectURL(blob);
  };
}

export const SpeechService = new SpeechServiceImpl();
