import styled from 'styled-components';
import { ColumnFlexbox, FlexWithSpacing } from 'app/typography/flex';
import { Colors } from 'app/themes/colors';
import { ErrorHint, TextRegular } from 'app/typography/text';

export const DatepickerContainer = styled(ColumnFlexbox)`
  position: relative;
  width: 100%;
`;

export const DatepickerComponent = styled(ColumnFlexbox)`
  margin-bottom: 5px;
`;

export const StyledDatepicker = styled.input`
  appearance: none;

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }

  &::-webkit-credentials-auto-fill-button {
    margin: 0;
    width: 0;
    background-color: transparent;
  }

  background: ${Colors.Blue400};
  border: none;
  border-radius: 0.378rem;
  color: ${Colors.Grey900};
  font-size: 0.875em;
  line-height: 1rem;
  padding: 12px 10px 12px 15px;
  height: 40px;
  box-sizing: border-box;
  cursor: pointer;

  &::placeholder {
    font-weight: normal;
    color: ${Colors.Grey700};
  }

  &:hover {
    box-shadow: 0 0 0 1px ${Colors.Blue700};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${Colors.Blue700};
    background: ${Colors.MainBackground};
    outline: none;
  }

  &:disabled {
    background: ${Colors.Grey500};
    color: ${Colors.Grey700};
    border: none;
    box-shadow: none;
    cursor: not-allowed;
  }

  &.error {
    box-shadow: 0 0 0 2px ${Colors.Red800};
    background: ${Colors.MainBackground};
  }
`;

export const IntervalFilter = styled(FlexWithSpacing)`
  align-items: center;
  justify-content: flex-start;
`;

export { ErrorHint, TextRegular };
