import styled from 'styled-components';

import { Button, Checkbox, Dialog, Textarea } from 'app/components/shared';
import { Colors } from '../../themes/colors';

export const StyledCheckbox = styled(Checkbox)`
  padding: 12px 12px;
  background-color: ${Colors.Blue400};
  border-radius: 6px;
  height: 100%;
`;

export const SaveButton = styled(Button)`
  padding: 15px 0;
  width: 120px;
`;

export const StyledDialog = styled(Dialog)`
  width: 550px;
`;

export const StyledTextArea = styled(Textarea)`
  max-height: 300px;
`;
