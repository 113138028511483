import React, { useEffect, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { IncomingResponse } from 'sip.js/lib/core';

import { SuppliersSelectors } from 'store/suppliers';
import { useCallsListener } from 'app/components/WebPhone/use-calls-listener';
import { WebPhoneModal } from 'app/components/WebPhone/WebPhoneModal';
import { useAction } from 'app/helpers/actions/use-action';
import { WebPhoneService } from 'app/services/webphone.service';
import { selectProfile } from 'store/profile/profile.selectors';
import { webphoneActions } from 'store/webphone/webphone.actions';
import { webPhoneSelectors } from 'store/webphone/webphone.selectors';
import { NotificationsActions } from 'store/notifications';
import { NotificationType } from 'app/models/notifications/notification';
import { Dialpad } from './Dialpad/Dialpad';

export const WebPhoneHandler = () => {
  const audioRef = useRef<HTMLAudioElement>(null);

  const initInputRinging = useAction(webphoneActions.InitIncomingRinging.init);
  const establishConnection = useAction(webphoneActions.establishedConnection);
  const establishingOutgoingConnection = useAction(webphoneActions.establishingOutgoingConnection);
  const handleSessionTermination = useAction(webphoneActions.handleSessionTermination.init);
  const setIsRegistered = useAction(webphoneActions.setIsRegistered);
  const establishIncomingConnection = useAction(webphoneActions.establishIncomingConnection);
  const addNotification = useAction(NotificationsActions.AddNotification.init);

  const isWebPhoneShown = useSelector(webPhoneSelectors.selectIsShowWebPhone);
  const isModalWebPhoneShown = useSelector(webPhoneSelectors.selectIsModalWebPhoneShown);
  const profile = useSelector(selectProfile);
  const suppliersPhoneLines = useSelector(SuppliersSelectors.selectSuppliersPhoneLines, shallowEqual);

  const hasSipCredentials = !!profile?.sipPassword && !!profile?.sipUri;

  const handleRegistered = () => {
    setIsRegistered(true);
  };

  const handleUnregistered = (response?: IncomingResponse) => {
    setIsRegistered(false);

    if (response?.message.reasonPhrase === 'Forbidden') {
      addNotification({
        body: 'Ошибка регистрации',
        type: NotificationType.Error,
      });
    }
  };

  useCallsListener(
    initInputRinging,
    establishConnection,
    establishingOutgoingConnection,
    establishIncomingConnection,
    handleSessionTermination
  );

  useEffect(() => {
    if (audioRef?.current && profile) {
      const { sipUri, sipPassword } = profile;
      if (sipUri && sipPassword) {
        WebPhoneService.start(audioRef?.current, { sipUri, sipPassword }, handleRegistered, handleUnregistered);
      }
    }
  }, [audioRef, profile]);

  useEffect(() => {
    if (!suppliersPhoneLines.length) return;
    WebPhoneService.phoneLines = suppliersPhoneLines;
  }, [suppliersPhoneLines]);

  return (
    <>
      <audio ref={audioRef} />
      {isModalWebPhoneShown && <WebPhoneModal />}
      {hasSipCredentials && <Dialpad isShown={!isWebPhoneShown} />}
    </>
  );
};
