import React, { useEffect, useRef } from 'react';
import { PagesRoutes } from 'app/constants/route-path';
import { useAction } from 'app/helpers/actions/use-action';
import { shallowEqual, useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
import { CallStatus } from 'store/webphone/models/call-status.enum';
import { webphoneActions } from 'store/webphone/webphone.actions';
import { webPhoneSelectors } from 'store/webphone/webphone.selectors';
import { ConsumerInfoTab } from 'app/pages/Consumer/components';
import { DateFormat } from 'app/helpers/date/time';
import { ItalicHeaderSmall } from 'app/typography/text';
import { formatPhoneNumber } from 'app/helpers/phone';
import { ModalMenu } from './ModalMenu';
import {
  ActiveTicketsInfo,
  AnswerIcon,
  BriefView,
  Buttons,
  CallInfo,
  CircleButton,
  DetailedView,
  HangupIcon,
  Header,
  MembersQueue,
  MembersQueueIcon,
  ModalPlacementInfo,
  OpenIcon,
  Participant,
  ParticipantLink,
  Timer,
  WebPhoneModalContainer,
} from './styled';

export const WebPhoneModal = () => {
  const callStatus = useSelector(webPhoneSelectors.selectCallStatus, shallowEqual);
  const participant = useSelector(webPhoneSelectors.selectParticipant, shallowEqual);
  const activeAppeals = useSelector(webPhoneSelectors.selectParticipantActiveAppeals, shallowEqual);
  const participantPhone = useSelector(webPhoneSelectors.selectParticipantPhone, shallowEqual);
  const callStartDate = useSelector(webPhoneSelectors.selectCallStartDate, shallowEqual);
  const membersQueueCount = useSelector(webPhoneSelectors.selectMembersQueueCount, shallowEqual);
  const supplier = useSelector(webPhoneSelectors.selectParticipantSupplier, shallowEqual);

  const answerCall = useAction(webphoneActions.answer);
  const hangupCall = useAction(webphoneActions.hangup);

  const navigate = useNavigate();
  const answerButtonRef = useRef<HTMLButtonElement>(null);

  const audioPlayer = useRef<HTMLAudioElement>(null);

  const playIncomingCallMelody = React.useCallback(() => {
    if (audioPlayer.current && callStatus === CallStatus.IncomingRinging) {
      audioPlayer.current.play();
    } else audioPlayer.current?.pause();
  }, [callStatus]);

  const onClickOpenIcon = React.useCallback(() => {
    if (!participant) {
      navigate(PagesRoutes.PAGES.CONSUMER_CREATE);
      return;
    }

    if (participant.consumerId) {
      navigate(
        generatePath(PagesRoutes.PAGES.CONSUMER, {
          consumerId: String(participant.consumerId),
        })
      );
    }
  }, [navigate, participant]);

  const handleActiveTicketInfoClick = React.useCallback(() => {
    if (activeAppeals?.length === 1) {
      navigate(
        generatePath(PagesRoutes.PAGES.APPEAL, {
          appealId: String(activeAppeals[0]?.id),
        })
      );
    } else {
      navigate({
        pathname: generatePath(PagesRoutes.PAGES.CONSUMER, {
          consumerId: String(participant?.consumerId),
        }),
        search: `?activeTab=${ConsumerInfoTab.AppealsHistory} `,
      });
    }
  }, [activeAppeals, navigate, participant?.consumerId]);

  useEffect(() => {
    playIncomingCallMelody();
  }, [playIncomingCallMelody]);

  if (!(participant || participantPhone)) return null;

  return (
    <WebPhoneModalContainer minWidth={participant ? '400px' : '350px'} callStatus={callStatus}>
      <audio ref={audioPlayer} loop src="/incoming_call_melody.mp3" />

      <ModalMenu />

      <DetailedView>
        <Header spacing="5px">
          {participant && activeAppeals && activeAppeals?.length > 0 && (
            <ActiveTicketsInfo onClick={handleActiveTicketInfoClick}>{`Активные заявки: ${activeAppeals?.length}`}</ActiveTicketsInfo>
          )}

          <Participant>
            {participant?.consumerId ? (
              <ParticipantLink
                to={generatePath(PagesRoutes.PAGES.CONSUMER, {
                  consumerId: String(participant?.consumerId),
                })}
              >
                {participant?.fullName}
              </ParticipantLink>
            ) : (
              formatPhoneNumber(participantPhone)
            )}
          </Participant>
          <ModalPlacementInfo spacing="5px">
            {participant?.addresses?.length === 1 && <span>{participant.addresses[0].address}</span>}

            {participant?.addresses?.length && participant.addresses.length > 1 && `Кол-во помещений: ${participant.addresses.length}`}

            {supplier && <span>{supplier.name}</span>}
          </ModalPlacementInfo>
        </Header>

        <CallInfo spacing="30px">
          {callStatus === CallStatus.InTalk && callStartDate && <Timer date={new Date(callStartDate)} format={DateFormat.mmss} />}

          {(callStatus === CallStatus.OutgoingRinging || callStatus === CallStatus.EstablishingIncomingCall) && (
            <ItalicHeaderSmall>Соединение</ItalicHeaderSmall>
          )}

          {membersQueueCount >= 0 && (
            <MembersQueue spacing="5px">
              <MembersQueueIcon icon="/assets/icons.svg#person" />

              <span>{membersQueueCount}</span>
            </MembersQueue>
          )}
        </CallInfo>
      </DetailedView>

      <BriefView>
        <Participant>{participant?.fullName || formatPhoneNumber(participantPhone)}</Participant>

        {callStatus === CallStatus.InTalk && callStartDate && <Timer date={new Date(callStartDate)} format={DateFormat.mmss} small />}

        {(callStatus === CallStatus.OutgoingRinging || callStatus === CallStatus.EstablishingIncomingCall) && (
          <ItalicHeaderSmall>Соединение</ItalicHeaderSmall>
        )}
      </BriefView>

      <Buttons spacing="30px">
        <CircleButton color="red" onClick={hangupCall} title="Сбросить">
          <HangupIcon icon="/assets/icons.svg#phone down" />
        </CircleButton>
        {(callStatus === CallStatus.InTalk || callStatus === CallStatus.EstablishingIncomingCall) && (
          <CircleButton color="blue" title="Открыть потребителя" onClick={onClickOpenIcon}>
            <OpenIcon icon="/assets/icons.svg#chevron-right" />
          </CircleButton>
        )}
        {callStatus === CallStatus.IncomingRinging && (
          <CircleButton color="green" title="Ответить" ref={answerButtonRef} onClick={answerCall}>
            <AnswerIcon icon="/assets/icons.svg#phone-in-talk" />
          </CircleButton>
        )}
      </Buttons>
    </WebPhoneModalContainer>
  );
};
