import { SpeechNotification } from 'app/models/speech-notification';
import { UpdateSpeechNotificationRequest } from 'app/services/speech-notification-service';
import { getCreateActionTrio } from '../helpers/action-create';

export const SPEECH_NOTIFICATION_STORE_NAME = 'speech-notification';

const createActionTrio = getCreateActionTrio(SPEECH_NOTIFICATION_STORE_NAME);

export const LoadSpeechNotification = createActionTrio<void, SpeechNotification>('load speech notification');

export const UpdateSpeechNotification = createActionTrio<UpdateSpeechNotificationRequest, SpeechNotification>('update speech notification');

export const SpeechNotificationActions = {
  LoadSpeechNotification,
  UpdateSpeechNotification,
};
