import styled from 'styled-components';
import { StyledLink, Table } from 'app/components/shared';
import { Colors } from 'app/themes/colors';
import { ColumnFlexbox, ColumnFlexWithPadding } from 'app/typography/flex';
import { media } from 'styles/media';
import { TextRegularBig } from 'app/typography/text';

export const Link = styled(StyledLink)`
  color: ${Colors.Blue800};
  font-weight: 500;
`;
export const TabComponent = styled(ColumnFlexbox)`
  padding: 20px 30px;
  height: 100%;
  gap: 30px;
  background-color: ${Colors.White};
  margin-top: 20px;
  border-radius: 10px;

  & > * {
    margin-right: auto;
  }

  ${media.md`
    flex-direction: row;
  `}
`;

export const Column = styled(ColumnFlexWithPadding)`
  min-width: 240px;
`;

export const AppealInfoTable = styled(Table)`
  thead {
    border-top: none;
  }

  .column-header {
    font-weight: 400;
  }
` as typeof Table;

export const ActionButton = styled(TextRegularBig)`
  color: ${Colors.Blue800};
  font-weight: 500;
  cursor: pointer;
`;
