import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextRegular } from 'app/typography/text';
import { PagesRoutes, RouteName, toolbarRoutes } from 'app/constants/route-path';
import { shallowEqual, useSelector } from 'react-redux';
import { NotificationsSelectors } from 'store/notifications';
import { useOnClickOutside } from 'app/helpers/click-outside/click-outside.hook';
import { webPhoneSelectors } from 'store/webphone';
import { profileSelectors } from 'store/profile';
import { ModalService } from 'app/services/modal-service/modal.service';
import { AutomaticVoiceNotificationsDialog } from 'app/components/AutomaticVoiceNotificationsDialog';
import { StyledLink } from '../Link';
import {
  Logotype,
  NotificationContainer,
  NotificationsContainer,
  ProfileControls,
  ProfileIconComponent,
  RobotIconComponent,
  RouteTabs,
  StyledIcon,
  TabTitle,
  ToolbarComponent,
  ToolbarNotificationsComponent,
} from './styled';
import { selectSpeechNotification } from '../../../../store/speech-notification/speech-notification.selectors';

export const Toolbar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isRegistered = useSelector(webPhoneSelectors.selectIsRegistered);
  const speechNotification = useSelector(selectSpeechNotification, shallowEqual);
  const profile = useSelector(profileSelectors.selectProfile);

  const hasSipCredentials = !!profile?.sipPassword && !!profile?.sipUri;

  const handleProfileIconClick = () => {
    navigate(PagesRoutes.PAGES.PROFILE);
  };

  const handleLogotypeIconClick = () => {
    navigate(PagesRoutes.PAGES.CONSUMERS);
  };

  const handleRobotIconClick = () => {
    ModalService.openModal(AutomaticVoiceNotificationsDialog as any, {});
  };

  return (
    <ToolbarComponent>
      <Logotype onClick={handleLogotypeIconClick} />
      <RouteTabs spacing="1.5625rem">
        {toolbarRoutes.map(route => (
          <TabTitle key={route} active={!pathname.indexOf(route)}>
            <StyledLink to={route}>
              <TextRegular>{RouteName[route]}</TextRegular>
            </StyledLink>
          </TabTitle>
        ))}
      </RouteTabs>
      <ProfileControls spacing="1.25rem">
        <RobotIconComponent isActive={speechNotification?.active}>
          <StyledIcon icon="/assets/icons.svg#robot" onClick={handleRobotIconClick} />
        </RobotIconComponent>
        <ToolbarNotifications />
        <ProfileIconComponent onClick={handleProfileIconClick} isRegistered={isRegistered} hasSipCredentials={hasSipCredentials}>
          <StyledIcon icon="/assets/icons.svg#person" />
        </ProfileIconComponent>
      </ProfileControls>
    </ToolbarComponent>
  );
};

const ToolbarNotifications = () => {
  const notifications = useSelector(NotificationsSelectors.selectInfoNotifications);

  const [notificationsOpen, setNotificationsOpen] = useState<boolean>(false);

  const notificationsRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(notificationsRef, () => setNotificationsOpen(false));

  const handleNotificationsIconClick = () => {
    setNotificationsOpen(prevState => !prevState);
  };

  return (
    <ToolbarNotificationsComponent ref={notificationsRef}>
      <StyledIcon onClick={handleNotificationsIconClick} icon="/assets/icons.svg#notification" />
      {notificationsOpen && (
        <NotificationsContainer>
          {notifications.length > 0 ? (
            notifications.map(notification => (
              <NotificationContainer key={notification.id}>
                <TextRegular>
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: notification.body }} />
                </TextRegular>
              </NotificationContainer>
            ))
          ) : (
            <NotificationContainer>
              <TextRegular>Нет уведомлений</TextRegular>
            </NotificationContainer>
          )}
        </NotificationsContainer>
      )}
    </ToolbarNotificationsComponent>
  );
};
