import styled from 'styled-components';

import { ColumnFlexbox, Flexbox } from 'app/typography/flex';
import { Colors } from 'app/themes/colors';
import { Dialog } from '../../shared';

export const OuterContainer = styled(Flexbox)`
  padding: 20px 30px 30px;
  justify-content: center;
  min-height: calc(100vh - 72px);

  &.editConsumer {
    padding: 0;
    min-height: max-content;
    max-width: 1050px;
    margin: 20px auto;
  }
`;

export const InnerContainer = styled(Flexbox)`
  gap: 20px;
  width: 100%;
`;

export const CallBox = styled(ColumnFlexbox)`
  max-width: max-content;
  gap: 16px;
  padding-bottom: 20px;
`;

export const ComponentWrapper = styled.div`
  background: ${Colors.MainBackground};
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
`;

export const WebPhoneSideModal = styled(Dialog)`
  position: relative;

  max-width: 248px;

  background-color: ${Colors.White};

  & .bodyClassName {
    border-radius: 0;
    padding: 0;
  }
`;
