import { all, call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { SpeechNotification } from 'app/models/speech-notification';
import { SpeechNotificationService, UpdateSpeechNotificationRequest } from 'app/services/speech-notification-service';
import { SpeechNotificationActions } from './speech-notification.actions';

function* loadSpeechNotification() {
  try {
    const speechNotification: SpeechNotification = yield call(SpeechNotificationService.loadSpeechNotification);

    yield put(SpeechNotificationActions.LoadSpeechNotification.success(speechNotification));
  } catch (error) {
    yield put(SpeechNotificationActions.LoadSpeechNotification.failure(error));
  }
}

function* updateSpeechNotification({ payload }: PayloadAction<UpdateSpeechNotificationRequest>) {
  try {
    const speechNotification: SpeechNotification = yield call(SpeechNotificationService.updateSpeechNotification, payload);

    yield put(SpeechNotificationActions.UpdateSpeechNotification.success(speechNotification));
  } catch (error) {
    yield put(SpeechNotificationActions.UpdateSpeechNotification.failure(error));
  }
}

export default function* watcher() {
  yield all([
    takeLatest(SpeechNotificationActions.LoadSpeechNotification.init, loadSpeechNotification),
    takeLatest(SpeechNotificationActions.UpdateSpeechNotification.init, updateSpeechNotification),
  ]);
}
