import styled from 'styled-components';
import { Flexbox } from 'app/typography/flex';
import { media } from 'styles/media';
import { Colors } from 'app/themes/colors';
import { Dialog, FormField, FormSelect, Datepicker } from 'app/components/shared';
import { TextRegular } from 'app/typography/text';

export const FormRow = styled(Flexbox)<{ offset?: string; spacing: string }>`
  margin-bottom: ${props => props.offset};
  flex-wrap: wrap;

  & > * {
    width: 100%;
  }

  ${media.lg`
    flex-wrap: nowrap;
    gap: ${props => props.spacing};
  `}
`;

export const StyledFormField = styled(FormField)`
  position: relative;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 15px;

  & > button {
    width: auto;
    padding: 13px 20px;
  }
`;

export const AddressesError = styled.div`
  font-size: 0.75rem;
  line-height: 0.875rem;
  margin: 5px auto 0;
  color: ${Colors.Red800};
`;

export const StyledDialog = styled(Dialog)`
  width: 760px;
`;

export const RecipientsCount = styled(TextRegular)`
  display: block;
  margin-top: 5px;

  position: absolute;
  top: 0;
  right: 0;

  & span {
    color: ${Colors.Grey700};
  }
`;

export const MailingSelect = styled(FormSelect)`
  min-width: 340px;
`;

export const MacrosContainer = styled(Flexbox)`
  flex-wrap: wrap;
  width: unset;
  column-gap: 20px;
`;

export const MacrosFormField = styled(FormField)`
  width: max-content;
`;
export const MailingDatePicker = styled(Datepicker)`
  height: 46px;
`;

export const TemplateText = styled.div`
  padding: 20px;
  border: 2px solid ${Colors.Blue400};
  border-radius: 6px;
`;

export const MailingStatusField = styled(TextRegular)`
  position: absolute;
  top: 3px;
  right: 0;
`;
